import { error } from "console";

import { RootState } from "./../../app/store/ConfigureStore";
import { Product, ProductParams } from "./../../app/models/product";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import agent from "../../app/API/agent";
import { MetaData } from "../../app/models/pagination";
import React from "react";
import ProductList from "./ProductList";
interface catalogState {
  productLoaded: boolean;
  filtersLoaded: boolean;
  productLoadedall: boolean;
  status: string;
  brands: string[];
  types: string[];
  ProductParams: ProductParams;
  metaData: MetaData | null;
}
interface catalogState1 {
  productLoaded: boolean;
  filtersLoaded: boolean;
  productLoadedall: boolean;
  status: string;
}
const prodctsAdapter = createEntityAdapter<Product>();
function getAxiosParams(ProductParams: ProductParams) {

  // console.log("getAxiosParams", ProductParams);
  
  const params = new URLSearchParams();
  params.append("pageNumber", ProductParams.pageNumber.toString());
  params.append("PageSize", ProductParams.PageSize.toString());
  params.append("orderBy", ProductParams.orderBy);
  if (ProductParams.searchTrem)
  params.append("searchTerm", ProductParams.searchTrem.toString());
if (ProductParams.brands.length > 0)
params.append("brands", ProductParams.brands.toString());
if (ProductParams.types.length > 0)
params.append("types", ProductParams.types.toString());

// console.log("params", params);
  return params;
}

export const fetchProductsAsync = createAsyncThunk<Product[],void,{ state: RootState }>
("catalog/fetchProductsAsync", async (_, thunkAPI) => {
  const params = getAxiosParams(thunkAPI.getState().catalog.ProductParams);

  try {
    const response = await agent.Catalog.List(params);
    thunkAPI.dispatch(setMetaData(response.metaData));
    // console.log("here ee:"+response.items);

    return response.items;
  } catch (error) {
    return thunkAPI.rejectWithValue({
      error: error.data,
    });
  }
});



export const fetchProductAsync = createAsyncThunk<Product, number>(
  "catalog/fetchProductAsync",
  async (productId, thunkAPI) => {
    try {
      return await agent.Catalog.details(productId);
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
  );

  export const fetchProductsAllAsync = createAsyncThunk<
    Product[],
    void,
    { state: RootState }
  >("catalog/fetchProductsAllAsync", async (_, thunkAPI) => {
    // const params = getAxiosParams(thunkAPI.getState().catalog.ProductParams);
  
    try {
      const response = await agent.Catalog.ListAll();
      // console.log("MR:",response.items);
  
      // thunkAPI.dispatch(setMetaData(response.metaData));
      // console.log("here ee:"+response.items);
  
      return response.items;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: error.data,
      });
    }
  });

  export const fetchProductAllAsync = createAsyncThunk<Product>(
    "catalog/fetchProductAllAsync",
    async (productId, thunkAPI) => {
      try {
        return await agent.Catalog.ListAll();
      } catch (error) {
        return thunkAPI.rejectWithValue({ error: error.data });
      }
    }
  );
  
export const fetchFilters = createAsyncThunk(
  "catalog/fetchFilters",
  async (_, thunkAPI) => {
    try {
      // localStorage.setItem('DDDD', JSON.stringify(agent.Catalog.fetchFilters()));
      return agent.Catalog.fetchFilters();
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.data });
    }
  }
);

function initParams() {
  return {
    pageNumber: 1,
    PageSize: 9,
    orderBy: "name",
    brands: [] as any[],
    types: [] as any[],
  };
}

export const catalogSlice = createSlice({
  name: "catalog",
  initialState: prodctsAdapter.getInitialState<catalogState>({
    productLoaded: false,
    filtersLoaded: false,
    productLoadedall: false,
    status: "idle",
    brands: [],
    types: [],
    ProductParams: initParams(),
    metaData: null,
  }),
  reducers: {
    setProductParams: (state, action) => {
      state.productLoaded = false;
      state.ProductParams = {
        ...state.ProductParams,
        ...action.payload,
        pageNumber: 1,
      };
    },
    setPageNumber: (state, action) => {
      state.productLoaded = false;
      state.ProductParams = { ...state.ProductParams, ...action.payload };
    },
    setMetaData: (state, action) => {
      state.metaData = action.payload;
    },
    restProductParams: (state, action) => {
      state.ProductParams = initParams();
    },
    setProduct: (state, action) => {
      prodctsAdapter.upsertOne(state, action.payload);
      state.productLoaded = false;
    },
    removeProduct: (state, action) => {
      prodctsAdapter.removeOne(state, action.payload);
      state.productLoaded = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductsAsync.pending, (state) => {
      state.status = "penddingFetchProducts";
    });
    builder.addCase(fetchProductsAsync.fulfilled, (state, action) => {
      if (action.payload) prodctsAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.productLoaded = true;
    });
    builder.addCase(fetchProductsAsync.rejected, (state, action) => {
      // console.log(action.payload);
      state.status = "idle";
    });
    builder.addCase(fetchProductAsync.pending, (state) => {
      state.status = "penddingFetchProduct";
    });
    builder.addCase(fetchProductAsync.fulfilled, (state, action) => {
      prodctsAdapter.upsertOne(state, action.payload);
      state.status = "idle";
    });

    builder.addCase(fetchFilters.pending, (state) => {
      state.status = "penddingfetchFilters";
    });
    builder.addCase(fetchFilters.fulfilled, (state, action) => {
      state.brands = action.payload.brands;
      state.types = action.payload.types;
      state.filtersLoaded = true;
    });
    builder.addCase(fetchFilters.rejected, (state, action) => {
      // console.log(action.payload);
      state.status = "idle";
    });
  },
});
export const catalogSlice1 = createSlice({
  name: "catalog",
  initialState: prodctsAdapter.getInitialState<catalogState1>({
    productLoaded: false,
    filtersLoaded: false,
    productLoadedall: false,
    status:"idle",
  }),
  reducers: {
 
    setProductall: (state, action) => {
      prodctsAdapter.upsertOne(state, action.payload);
      state.productLoadedall = false;
    },
    removeProduct: (state, action) => {
      prodctsAdapter.removeOne(state, action.payload);
      state.productLoadedall = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductsAllAsync.pending, (state) => {
      state.status = "penddingFetchProducts";
    });
    builder.addCase(fetchProductsAllAsync.fulfilled, (state, action) => {
      if (action.payload) prodctsAdapter.setAll(state, action.payload);
      state.status = "idle";
      state.productLoadedall = true;
    });
    builder.addCase(fetchProductsAllAsync.rejected, (state, action) => {
      // console.log(action.payload);
      state.status = "idle";
    });
    builder.addCase(fetchProductAllAsync.pending, (state) => {
      state.status = "penddingFetchProduct";
    });
    // builder.addCase(fetchProductsAllAsync.fulfilled, (state, action) => {
    //   prodctsAdapter.upsertOne(state, action.payload);
    //   state.status = "idle";
    // });

   
  },
});
export const productSelectors = prodctsAdapter.getSelectors(
  (state: RootState) => state.catalog
);
export const {
  setProductParams,
  restProductParams,
  setMetaData,
  setPageNumber,
  setProduct,

  removeProduct,
} = catalogSlice.actions;
export const {
 
  setProductall

} = catalogSlice1.actions;