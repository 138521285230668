import { Button, ButtonGroup, ListItem, Typography } from "@mui/material";
import React from 'react';
import { useAppDispatch, useAppSelector } from "../../app/store/ConfigureStore";
import { Decrement, increment } from "./countrySlice";
import { Grid, Paper, Box } from "@mui/material";
import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import useProducts from "../../app/hooks/useProducts";
import { Contect, Contect2, Product } from "../../app/models/product";
import {yupResolver} from '@hookform/resolvers/yup';
import { LoadingButton } from "@mui/lab";
import AppTextInput from "../../app/Components/AppTextInput";
import CallIcon from '@mui/icons-material/Call';
import { string } from "yup";
import { validationSchema } from "../Checkout/checkoutValidation";

import { addContects } from "../../app/API/contactService";
import { Link, NavLink, useHistory } from "react-router-dom";

interface Props {
    product?: Contect;
    cancelEdit: () => void;
}
export default function ContactPage({ product, cancelEdit }: Props) {
  const { control, reset, handleSubmit, watch, formState: {isDirty, isSubmitting} } = useForm({
      mode: 'all'
    //   resolver: yupResolver<any>(validationSchema)
  });



  const history = useHistory();

  useEffect(() => {
   
  }, [product, reset, isDirty]);

  async function handleSubmitData(data: FieldValues) {
    console.log("here data to add",data)
    try {

        await addContects(data);
        let path = `/Catalog`; 
        history.push(path);

    } catch (error) {
        console.log(error)
    }
}

  
return (
  <>


  <Box component={Paper} sx={{ p: 4 }}>

  <Typography variant="h2" gutterBottom sx={{ mb: 4,textAlign:'center' }}>
  Contact Us
  <CallIcon fontSize="large"/> <br/>
  </Typography>

  <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
  You can order any service we contact you within 24 hours
  </Typography>
  


  <form onSubmit={handleSubmit(handleSubmitData)}>
      <Grid container spacing={3}>  
      <Grid item xs={12} sm={12}>
              <AppTextInput control={control} name='fullName' label='Full Name' />
          </Grid>   
           <Grid item xs={12} sm={12}>
              <AppTextInput control={control} name='phone' label='Phone Number' />
          </Grid>  
            <Grid item xs={12} sm={12}>
              <AppTextInput control={control} name='address' label='Address' />
          </Grid>  
          <Grid item xs={12} sm={12}>
              <AppTextInput control={control} name='email' label='Email Address' />
          </Grid> 
          <Grid item xs={12} sm={12}>
              <AppTextInput control={control} name='productName' label='Product Name' />
          </Grid> 
   
          <Grid item xs={12}>
              <AppTextInput multiline={true} rows={4} control={control} name='desc' label='Description' />
          </Grid>
            {/* <Grid item xs={12} sm={12}>
              <AppTextInput control={control} name='conDate' label='Register date' />
          </Grid> */}
          <Grid item xs={12} sm={12}>
              <AppTextInput multiline={true} rows={4}  control={control} name='timeToContect' label='Information About Contect' />
          </Grid>

       
      </Grid>
      <Box display='flex' justifyContent='space-between' sx={{ mt: 3 }}>
          <Button onClick={cancelEdit} variant='contained' color='inherit'>Cancel</Button>

          {/* <ListItem 
          component={NavLink}
           to={'/Catalog'} 
         > */}
            {/* <Link to={'/Catalog'} onClick={isSubmitting}>
                </Link> */}
          <LoadingButton loading={isSubmitting} type='submit' variant='contained' color='success'>
            
                Submit
            </LoadingButton>
   {/* </ListItem> */}
          
          
      </Box>
  </form>
</Box> 
 </>
  )
}
