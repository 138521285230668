import { basketSlice } from './../../features/Basket/basketSlice';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { countrySlice } from './../../features/Contact/countrySlice';
import { configureStore } from '@reduxjs/toolkit';
// import { createStore } from "redux";
import counterReducer from "../../features/Contact/CounterReducer";
import { catalogSlice } from '../../features/Catalog/CatalogSlice';
import { accountSlice } from '../../features/account/accountSlice';

export const store = configureStore({
    reducer: {  
        counter: countrySlice.reducer,
        basket:basketSlice.reducer,
        catalog:catalogSlice.reducer,
        account:accountSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch =typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector:TypedUseSelectorHook<RootState> = useSelector;