import { createSlice } from "@reduxjs/toolkit";

export interface CounterState {
    data: number;
    title:string;
}

const initialState:CounterState ={
data:42,
title:'YARC (yet another redux counter with redux toolkit )'
}

export const countrySlice=createSlice({
    name: 'country',
    initialState,
    reducers: { 
        increment: (state, action) => {
            state.data += action.payload
    },
    Decrement: (state, action) => {
        state.data -= action.payload
}
}
})

export const {increment,Decrement} = countrySlice.actions;