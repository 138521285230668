import { Button, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import agent from "../../app/API/agent";
import NotFound from "../../app/Errors/NotFound";
import LoadingComponent from "../../app/layout/LoadingComponent";
// import { Product } from "../../app/models/product";
import React from 'react';
// import { useStoreContext } from "../../app/Context/storecontext";
import { LoadingButton } from "@mui/lab";
// import { error } from "console";
import { Basket } from "../../app/models/Basket";
import { useAppDispatch, useAppSelector } from "../../app/store/ConfigureStore";
import { addBasketItemAsync, setBasket } from "../Basket/basketSlice";
import { fetchProductAsync, productSelectors } from "./CatalogSlice";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
interface BasketState {

    basket: Basket | null;
    status: string;
    item?:string;
}

const initialState: BasketState = {
    basket: null,
    status: 'idle'
}

export default function ProductDetails() {
const {basket,status}= useAppSelector(state => state.basket);
const dispatch = useAppDispatch();
const {id} =useParams<{id:string}>();
// const [product,setProducts]= useState<Product|null>(null);
const product=useAppSelector(state => productSelectors.selectById(state,id));
// const[loading,setloading]=useState(true);
const {status:productStatus} = useAppSelector(state => state.catalog);
const [quantity, setQuantity] = useState(0);
let item: any;
const backToNumber = Number(id);
if(basket) {
 item  = basket.items.find(i => i.productId === Number(backToNumber));
// const newitem = JSON.stringify(basket.items);
// console.log("this is my new item: "+ newitem);
}
 useEffect(() => {
   if(item) setQuantity(item.quantity);
   if(!product) dispatch(fetchProductAsync(parseInt(id)));

    localStorage.setItem("product", JSON.stringify(item));
//   },[id])
},[id,item,dispatch,product]

)

var ss=localStorage.getItem("product");
// var bb=JSON.stringify(ss);
console.log("this is  all data 2222:" + ss);

function handleInputChange(event: any) {
    if (event.target.value > 0) {
        setQuantity(parseInt(event.target.value));
    }
}


function handleUpdateCart() {
    // setSubmitting(true);
    let updatedQuantity:any;
    if (!item || quantity > item.quantity) {
        if (item)
         updatedQuantity = quantity - item.quantity 
        else
        {
         updatedQuantity =quantity
        }
        // const updatedQuantity = item ? quantity - item.quantity : quantity;
        if(product)
    dispatch(addBasketItemAsync({productId:product.id!, quantity:updatedQuantity}))
    } else {
        if(product){
        const updatedQuantity = item.quantity - quantity;
        dispatch(addBasketItemAsync({productId:product.id!, quantity:updatedQuantity}))

   } }
}

if(productStatus.includes('pending')) return <LoadingComponent message='details loading...' />

if(!product) return <NotFound/>
// if(!item) return <LoadingComponent message='details loading...' />

return (
    <div>
        <Grid container spacing={6}>
            <Grid item xs={6}>
                <img src={product.pictureUrl} alt={product.name} style={{width: '100%'}} />
            </Grid>
            <Grid item xs={6}>
                <Typography variant='h4'>{product.name}</Typography>
                <Divider sx={{mb: 2}} />
                <Typography variant='h4' color='secondary'>${(product.price / 100).toFixed(2)}</Typography>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>{product.name}</TableCell>
                            </TableRow>    
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>{product.description}</TableCell>
                            </TableRow>  
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>{product.type}</TableCell>
                            </TableRow>  
                            <TableRow>
                                <TableCell>Brand</TableCell>
                                <TableCell>{product.brand}</TableCell>
                            </TableRow>  
                            <TableRow>
                                <TableCell>Quantity in stock</TableCell>
                                <TableCell>{product.quantityInStock}</TableCell>
                            </TableRow>  
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {/* <TextField 

                        onChange={handleInputChange}
                            variant='outlined'
                            type='number'
                            label='Quantity in Cart'
                            fullWidth
                            value={quantity}
                        /> */}
                    </Grid>
                    <Grid item xs={6}>
                        {/* <LoadingButton
         
                     
                            loading={status.includes('pending'+ id)}
                            onClick={handleUpdateCart}
                            sx={{height: '55px'}}
                            color='primary'
                            size='large'
                            variant='contained'
                            fullWidth
                        >
                            {item ? 'Update Quantity' : 'Add to Cart'}
                     
                        </LoadingButton> */}
          {/* <Button component={Link} to={`/Catalog}`} size="large" style={{margin:'Center'}} >  <RemoveRedEyeIcon/> </Button> */}
          <Button fullWidth component={Link}  color='primary'
                            size='large'
                            variant='contained'  style={{marginTop:'120px'}} sx={{height: '50px'}} to='/catalog'>Go back</Button>
                    </Grid>
                </Grid>             
             
            </Grid>
        </Grid>
    </div>
  )
}
