// import axios from 'axios';
// import { json } from 'stream/consumers';

import axios from "axios";
import authHeader from './authHeader';

const getalldataUrl = 'https://goldenstarluster.com/';
// const deletedataUrl = 'https://localhost:7051/Complaint';


export const getcontectbyId = async (id:any) => {
    id = id || '';
    try {
        return await axios.get(`${getalldataUrl}Contect/${id}`);
    } catch (error) {
        console.log('Error while calling get Contect api ', error);
    }
}

export const getallContects = async () => {
  
    try {
        let users = await axios.get(`${getalldataUrl}Contect/GetAllContects`);
        return users.data;
        
    } catch (error) {
        console.log('Error while calling getallContects api ', error);
    }
}

export const GetProductsByType = async () => {
  
    try {
        let users = await axios.get(`${getalldataUrl}Product/GetProductsByType?type=`+'Work');
        return users.data;
        
    } catch (error) {
        console.log('Error while calling GetProductsByType api ', error);
    }
}

function createFormData(item: any) {
    let formData = new FormData();
    for (const key in item) {
        formData.append(key, item[key])
    }
    return formData;
}

export const addContects = async (user:any) => {
    // console.log("this data to add :",user);
    return await axios.post(`${getalldataUrl}Contect`,createFormData(user) );
}

export const deleteContects = async (id:any) => {
    return await axios.delete(`${getalldataUrl}Contect/${id}`);
    
}


export const editContects = async (id:any, user:any) => {
    try
    {
    return await axios.put<any>(`${getalldataUrl}Contect`,user);
} catch (error:any) {
    if (axios.isAxiosError(error)) {
    //   console.log('error message: ', error.message);
      return error.message;
    } else {
    //   console.log('unexpected error: ', error);
      return 'An unexpected error occurred';
    }
}
}