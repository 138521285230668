 import { LoadingButton } from "@mui/lab";
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import React, { useState } from 'react';
import { Link } from "react-router-dom";
// import agent from "../../app/API/agent";
// import { useStoreContext } from "../../app/Context/storecontext";
import { Product } from "../../app/models/product";
import { useAppDispatch, useAppSelector } from "../../app/store/ConfigureStore";
import { currencyFormat } from "../../app/util/util";
import { addBasketItemAsync, setBasket } from "../Basket/basketSlice";

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

interface props{
    products:Product;
   
}
export default function ProductCard({products}:props)
{
const PID2 = Number(products.id);
const {status}=useAppSelector(state => state.basket);
const dispatch = useAppDispatch();

    return (

        <Card>
      <CardHeader
      avatar={
        <Avatar sx={{bgcolor:'primary.main'}} >
            {products.name.charAt(0).toUpperCase()}
        </Avatar>

      }
      title={products.name}
      titleTypographyProps={{sx:{fontWeight:'bold', color:'primary.main' }}}
      />
    
        <CardMedia
          component="img"
          height="140"
          image={products.pictureUrl}
          alt={products.name}
        />
        <CardContent>
          <Typography gutterBottom color='secondary' variant="h5" >
         {currencyFormat(products.price)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
        {products.brand}--{products.type}
          </Typography>
        </CardContent>
        <CardActions>
         {/* <LoadingButton loading={status.includes('pendingAddItem'+ PID2)} 
         onClick={() => dispatch(addBasketItemAsync({productId:PID2}))}
          size="small"> Add to cart </LoadingButton> */}
          <Button component={Link} to={`/Catalog/${products.id}`} size="large" style={{margin:'Center'}} >  <RemoveRedEyeIcon/> </Button>
          {/* <Button component={Link} to={`/Catalog/${products.id}`} size="large" style={{marginLeft:'70px'}} >  <RemoveRedEyeIcon/> </Button> */}
        </CardActions>
      </Card>
    )
}