import { Container, Grid, Stack, Typography } from "@mui/material";
import ProductList from "./ProductList";
import LoadingComponent from "./../../app/layout/LoadingComponent";
import React from "react";
import { useState } from "react";
import useProducts from "../../app/hooks/useProducts";
import { useAppDispatch } from "../../app/store/ConfigureStore";
import ProductFilter from "../MainHeader/ProductFilter";
import ShopSortPro from "../MainHeader/ShopSortPro";
import AppPagination from "../../app/Components/AppPagination";
import { setPageNumber } from "./CatalogSlice";

export default function Catalog() {
  const { products, filtersLoaded, metaData } = useProducts();
  // localStorage.setItem('productsKey', JSON.stringify(products));
  const dispatch = useAppDispatch();
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  if (!filtersLoaded) return <LoadingComponent message="loading Product..." />;

  return (
    <>
      <Container>
        {/* <Typography variant="h4" sx={{ mb: 2 }}>
          Products
</Typography> */}

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilter
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              icon={""}
            />
            <ShopSortPro />
          </Stack>
        </Stack>
        <ProductList products={products} />
      </Container>
      <Grid item xs={12} sm={9} sx={{ my: 5 }}>
        {metaData && (
          <AppPagination
            metaData={metaData}
            onPageChange={(page: number) =>
              dispatch(setPageNumber({ pageNumber: page }))
            }
          />
        )}
      </Grid>
    </>
  );
}
