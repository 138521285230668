import { Alert, AlertTitle, Button, ButtonGroup, Container, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useState } from "react";

import React from 'react';
import agent from "../../app/API/agent";
import '../About/AboutPage.css';
import Marquee from "react-fast-marquee";
export default function AboutPage() {
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    function getValidationError() {
        agent.TestErrors.getValidationError()
            .then(() => console.log('should not see this'))
            .catch(error => setValidationErrors(error));
    }

    return (
//         <Container>
//             {/* <Typography gutterBottom variant='h2'>Errors for testing purposes</Typography>
//             <ButtonGroup fullWidth>
//                 <Button variant='contained' onClick={() => agent.TestErrors.get400Error().catch(error => console.log(error))}>Test 400 Error</Button>
//                 <Button variant='contained' onClick={() => agent.TestErrors.get401Error().catch(error => console.log(error))}>Test 401 Error</Button>
//                 <Button variant='contained' onClick={() => agent.TestErrors.get404Error().catch(error => console.log(error))}>Test 404 Error</Button>
//                 <Button variant='contained' onClick={() => agent.TestErrors.get500Error().catch(error => console.log(error))}>Test 500 Error</Button>
//                 <Button variant='contained' onClick={getValidationError}>Test Validation Error</Button>
//             </ButtonGroup>
//             {validationErrors.length > 0 && 
//                 <Alert severity='error'>
//                     <AlertTitle>Validation Errors</AlertTitle>
//                     <List>
//                         {validationErrors.map(error => (
//                             <ListItem key={error}>
//                                 <ListItemText>{error}</ListItemText>
//                             </ListItem>
//                         ))}
//                     </List>
//                 </Alert>
//             }  */}
            
// <div classNameName="about-section">
//   <h1>About Us Page</h1>
//   <p>Some text about who we are and what we do.</p>
//   <p>Resize the browser window to see that this page is responsive by the way.</p>
// </div>

// <h2 >Our Team</h2>

// <div classNameName="row">
//   <div classNameName="column">
//     <div classNameName="card">
//       {/* <img src="/w3images/team1.jpg" alt="Jane" > */}
//       <div classNameName="container">
//         <h2>Jane Doe</h2>
//         <p classNameName="title">CEO & Founder</p>
//         <p>Some text that describes me lorem ipsum ipsum lorem.</p>
//         <p>jane@example.com</p>
//         <p><button classNameName="button">Contact</button></p>
//       </div>
//     </div>
//   </div>

//   <div classNameName="column">
//     <div classNameName="card">
//       {/* <img src="/w3images/team2.jpg" alt="Mike" > */}
//       <div classNameName="container">
//         <h2>Mike Ross</h2>
//         <p classNameName="title">Art Director</p>
//         <p>Some text that describes me lorem ipsum ipsum lorem.</p>
//         <p>mike@example.com</p>
//         <p><button classNameName="button">Contact</button></p>
//       </div>
//     </div>
//   </div>
  
//   <div classNameName="column">
//     <div classNameName="card">
//       {/* <img src="/w3images/team3.jpg" alt="John" > */}
//       <div classNameName="container">
//         <h2>John Doe</h2>
//         <p classNameName="title">Designer</p>
//         <p>Some text that describes me lorem ipsum ipsum lorem.</p>
//         <p>john@example.com</p>
//         <p><button classNameName="button">Contact</button></p>
//       </div>
//     </div>
//   </div>

// </div>

//         </Container>
<>
<div className="about-section">
  <h1>About Us :</h1>
  <p>We are a company working in the field of decoration within the UAE from 2020.</p>
  <p>We have businesses within the largest government institutions and famous stores and You can view them through this website.</p>
  <p>Contects : </p>
  <p>Email: goldenlustera@gmail.com</p>
  <p>Phone: +971555475259 </p>
  <p>Address: Sharjah , Dubai , Ajman , Abu Dhabi , Ras Khaimah , Fujairah</p>

</div>

{/* <h2 style ={{alignContent:'center'}} >Our Team</h2> */}
<Marquee margin-Left="250px" margin-Right="250px" direction="left" style={{background:'#1976d2',color:'white'}}>
<p> <strong style={{color:'#b5cb99'}}> You can Contect By: </strong> Email: goldenlustera@gmail.com || <strong style={{color:'red'}}>Phone: +971555475259</strong> || <strong style={{color:'#fff2d8'}}>Address: Sharjah , Dubai , Ajman , Abu Dhabi , Ras Khaimah , Fujairah</strong></p>

</Marquee>
<div className="row">
 
<div className="column">
</div>

  <div className="column">
    <div className="card">
      {/* <img src="/w3images/team2.jpg" alt="Mike" style={{width:100 }}> */}
      <img src="/images/AhmedAmer.jpg" alt="GOLDEN STAR LUSTER" style={{ width: '100%',height:'350px' }}  />

      <div className="container">
        <h2>Ahmed Amer</h2>
        <p className="title">CEO</p>
        <p>Vision without just implementing a dream and implementation without seeing a mere waste of time, and implementation together, can change the world.</p>
        {/* <p>Email: goldenlustera@gmail.com</p>
        <p>Phone: +971555475259</p>
        <p>Address:Sharjah , Dubai,Ajman,Abu Dhabi,Ras Khaimah,Fujairah</p> */}
        {/* <p><button className="button">Contact</button></p> */}
      </div>
    </div>
  </div>
  <div className="column">
</div>

</div>
</>
    )
}

