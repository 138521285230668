import { Remove, Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { error } from 'console';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import agent from '../../app/API/agent';
import { useAppDispatch, useAppSelector } from '../../app/store/ConfigureStore';
import {  addBasketItemAsync, removeBasketItemAsync, setBasket } from './basketSlice';
import BasketSummary from './BasketSummary';
import BasketTable from './BasketTable';

export default function BasketPage() {
const {basket, status} = useAppSelector(state=>state.basket);
const dispatch= useAppDispatch();
if(!basket){return <Typography variant='h3'> Your Basket is Empty </Typography>} 
else {

  return (
  <>
  <BasketTable items={basket.items}/>
    {/* <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }}>
        <TableHead>
            <TableRow>
                <TableCell>Product</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="right">Subtotal</TableCell>
                 <TableCell align="right"></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {basket.items.map(item => (
                <TableRow
                    key={item.productId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        <Box display='flex' alignItems='center'>
                            <img src={item.pictureUrl} alt={item.name} style={{ height: 50, marginRight: 20 }} />
                            <span>{item.name}</span>
                        </Box>
                    </TableCell>
                    <TableCell align="right">${(item.price / 100).toFixed(2)}</TableCell>
                  
                    <TableCell align="right">
                    <LoadingButton 
                     loading={status === 'pendingRemoveItem'+ item.productId +'rem'} 
                    onClick={()=> dispatch(removeBasketItemAsync({productId:item.productId,quantity:1,name:'rem'}))} 
                    
                    color='error'>
                        <Remove/>
                        
                      </LoadingButton>
                        {(item.quantity)}
                    <LoadingButton
                                  loading={status==='pendingAddItem'+ item.productId}
                                  onClick={() => dispatch(addBasketItemAsync({productId:item.productId}))} 
                     color='secondary'>
                        <Add/>
                        
                      </LoadingButton>
                    </TableCell>
                  
                    <TableCell align="right">${(item.price * item.quantity).toFixed(2)}</TableCell>
                    <TableCell align="right">
                      <LoadingButton 
                 loading={status === 'pendingRemoveItem'+ item.productId+'del'} 
                 onClick={()=> dispatch(removeBasketItemAsync(
                    {productId:item.productId,quantity:item.quantity,name:'del'}))} 
                        color='error'>
                        <Delete/>
                        
                      </LoadingButton>
                      </TableCell>
      
                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer> */}
<Grid container>
<Grid item xs={6}>

</Grid>
<Grid item xs={6}>
<BasketSummary></BasketSummary>
<Button component={Link} to='/Checkout' 
 variant='contained'
 size='large'
  fullWidth
  >
    Checkout
</Button>
</Grid>
</Grid>
</>
  )
}}

function setStatus(arg0: { loading: boolean; name: string; }): void {
    throw new Error('Function not implemented.');
}

