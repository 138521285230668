import axios, { AxiosError, AxiosResponse } from "axios";
// import { resolve } from "path";
import { toast } from "react-toastify";
import { history } from "../..";
import { PaginatedResponse } from "../models/pagination";
import { store } from "../store/ConfigureStore";

const sleep = () => new Promise(resolve => setTimeout(resolve,1000));

axios.defaults.baseURL='https://goldenstarluster.com/';
axios.defaults.withCredentials = true;
const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use(config => {
    if(store.getState().account.user){
    const token = store.getState().account.user.token;
    if (token) config.headers!.Authorization = `Bearer ${token}`;
}
    return config;  
})

axios.interceptors.response.use(async response => {
    await sleep();
    let pagination = response.headers['pagination'];
    if (pagination) {
        response.data = new PaginatedResponse(response.data, JSON.parse(pagination));
        return response;
    }
    return response;
},(error: AxiosError) => {
    const { data, status }:any = error.response;
 switch (status) {
    case 400:
        if (data.errors) {
            const modelStateErrors: string[] = [];
            for (const key in data.errors) {
                if (data.errors[key]) {
                    modelStateErrors.push(data.errors[key])
                }
            }
            throw modelStateErrors.flat();
        }
        toast.error(data.title);
        break;
        case 401:
    toast.error(data.title || 'Unauthorised');
     break;
      case 403:
        toast.error('You are not allowed to do that!');
       break;
      case 500:
        history.push({
            pathname: '/Server-Error',
            state: {error: data}
        });
        break;
    default:
        break;
 }
    return Promise.reject(error.response);
})

const requests = {
    getall: (url: string) => axios.get(url).then(responseBody),
    get: (url: string, params?: URLSearchParams) => axios.get(url, {params}).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
    postForm: (url: string, data: FormData) => axios.post(url, data, {
        headers: {'Content-type': 'multipart/form-data'}
    }).then(responseBody),
    putForm: (url: string, data: FormData) => axios.put(url, data, {
        headers: {'Content-type': 'multipart/form-data'}
    }).then(responseBody)
}

const Catalog = {
   
    ListAll: () => requests.get('Product'),
    List:(params:URLSearchParams) => requests.get('Product',params),
    details: (id:number) => requests.get(`Product/${id}`),
    fetchFilters:()=> requests.get('Product/filters')

}

function createFormData(item: any) {
    let formData = new FormData();
    for (const key in item) {
        formData.append(key, item[key])
    }
    return formData;
}
const Admin = {
    createProduct: (product: any) => requests.postForm('Product', createFormData(product)),
    updateProduct: (product: any) => requests.putForm('Product', createFormData(product)),
    deleteProduct: (id: number) => requests.delete(`Product/${id}`)
}
const contect = {
    createcontect: (contect: any) => requests.postForm('Contect', createFormData(contect)),
    updatecontect: (contect: any) => requests.putForm('Contect', createFormData(contect)),
    deletecontect: (id: number) => requests.delete(`Contect/${id}`),
    fetch: (id: number) => requests.get(`Contect/${id}`),
    list: () => requests.get('Contect/GetAllContects'),


}
const Account = {
    login: (values: any) => requests.post('Account/login', values),
    register: (values: any) => requests.post('Account/register', values),
    currentUser: () => requests.get('account/currentUser'),
    fetchAddress: () => requests.get('account/savedAddress')
}
const Orders = {
    list: () => requests.get('orders'),
    fetch: (id: number) => requests.get(`orders/${id}`),
    create: (values: any) => requests.post('orders', values)
}

const Payments = {
    createPaymentIntent: () => requests.post('Payments', {})
}
const TestErrors={
    get404Error:()=>requests.get('Buggy/not-found'),
    get400Error:()=>requests.get('Buggy/bad-requst'),
    get401Error:()=>requests.get('Buggy/Unauthorised'),
    get500Error:()=>requests.get('Buggy/Server-Error'),
    getValidationError:()=>requests.get('Buggy/Validation-Error'),
}

const Basket = {
    get: () => requests.get('Basket'),
    addItem: (productId: number, quantity = 1) => requests.post(`Basket?productId=${productId}&quantity=${quantity}`, {}),
    removeItem: (productId: number, quantity = 1) => requests.delete(`Basket?productId=${productId}&quantity=${quantity}`)
}


const agent = {
    Catalog,
    TestErrors,
    Basket,
    Account,
    Orders,
    Payments,
    Admin
}

export default agent;