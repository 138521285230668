import {Container, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import React from 'react'
import ReactDOM from 'react-dom';
import AboutPage from "../../features/About/AboutPage";
import Catalog from "../../features/Catalog/Catalog";
import Header from "../../features/Catalog/Header";
import ProductDetails from "../../features/Catalog/ProductDetails";
import 'react-toastify/dist/ReactToastify.css';
import ContactPage from "../../features/Contact/ContactPage";
import HomePage from "../../features/Home/HomePage";
import { ToastContainer } from "react-toastify";
import ServerError from "../Errors/ServerError";
import NotFound from "../Errors/NotFound";
import BasketPage from "../../features/Basket/BasketPage";
import { error } from "console";
import LoadingComponent from "./LoadingComponent";
import {createStore} from "redux";
import { useAppDispatch } from "../store/ConfigureStore";
import { fetchBasketAsync, setBasket } from "../../features/Basket/basketSlice";
import Login from "../../features/account/Login";
import Register from "../../features/account/Register";
import { fetchCurrentUser } from "../../features/account/accountSlice";
import PrivateRoute from "./PrivateRoute";
import Orders from "../../features/orders/Orders";
import CheckoutWrapper from "../../features/Checkout/CheckoutWrapper";
import Inventory from "../../features/admin/Inventory";
import AllContect from "../../features/Contact/allContect";
import MainNav from "../../features/MainHeader/MainNav";
// import MainNav from "../../features/MainHeader/MainNav";

function App() {
const dispatch = useAppDispatch();
const[loading,setLoading]= useState(true);
const initApp = useCallback(async () =>  {
  try { 
await dispatch(fetchCurrentUser());
await dispatch(fetchBasketAsync());
     }
  catch (error)
   {
     console.log(error);
   }
},[dispatch])
useEffect(() => {
initApp().then(() => setLoading(false));

},[initApp])

const [darkMode, setDarkMode] = useState(false);
const paletteType = darkMode ? 'dark' : 'light'
function handleThemeChange()
{
  setDarkMode(!darkMode);
}
if(loading) return <LoadingComponent message="Initialising app ..."/>

const theme=createTheme({
palette:{
mode:paletteType,
background:{
default: paletteType === 'light'?'#eaeaea':'#121212'
}
}
})
  return (
   
    <ThemeProvider theme={theme}>
      <ToastContainer position='bottom-right' hideProgressBar />
    <div  >
  <CssBaseline></CssBaseline>
  {/* <Header darkMode={darkMode} handleThemeChange={handleThemeChange}/> */}
  <MainNav />
 

<Route exact path='/' component={HomePage} />
      <Route path={'/(.+)'} render={() => (
        <Container  sx={{ mt: 1 }}>
          <Switch>
<Route exact path='/Catalog' component={Catalog}/>
<Route  path='/Catalog/:id' component={ProductDetails}/>
<Route  path='/About' component={AboutPage}/>
<Route  path='/Contact' component={ContactPage}/>
<Route  path='/mainNav' component={MainNav}/>
<Route  path='/Server-Error' component={ServerError}/>
<Route  path='/basket' component={BasketPage}/>
{/* <Route  path='/AllContect' component={AllContect}/> */}

<PrivateRoute  path='/Checkout' component={CheckoutWrapper}/>
<PrivateRoute  path='/orders' component={Orders}/>

<PrivateRoute roles={['Admin']} path='/inventory' component={Inventory} />
<PrivateRoute roles={['Admin']} path='/allContect' component={AllContect} />

<Route  path='/Login' component={Login}/>
<Route  path='/register' component={Register}/>
<Route   component={NotFound}/>

</Switch>

</Container>
    )} />
    </div>
    </ThemeProvider>
  );
}

export default App;
