import { Grid} from "@mui/material";
import { Product } from "../../app/models/product";
import ProductCard from "./ProductCard";
import React from 'react';
import { useAppSelector } from "../../app/store/ConfigureStore";
import ProductCardSkeleton from "./ProductCardSkeleton";
interface props{
    products:Product[];
   
}
export default function ProductList({products}:props){
    const { productLoaded } = useAppSelector(state => state.catalog);
    // localStorage.setItem('dataKey', JSON.stringify(products));
    return (
 
        
        <Grid container spacing={4}>
        {products.map(product => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
                {!productLoaded ? (
                    <ProductCardSkeleton />
                ) : (
                    <ProductCard products={product}  />
                )}
            </Grid>
        ))}
    </Grid>
    )
}