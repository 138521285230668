import { useEffect } from "react";
import { fetchFilters, fetchProductsAllAsync, fetchProductsAsync, productSelectors } from "../../features/Catalog/CatalogSlice";
import { useAppDispatch, useAppSelector } from "../store/ConfigureStore";

export default function useProducts() {
    const products = useAppSelector(productSelectors.selectAll);
    const productsall = useAppSelector(productSelectors.selectAll);
    const { productLoaded,productLoadedall, filtersLoaded, brands, types, metaData } = useAppSelector(state => state.catalog);
    // const { productLoadedall, filtersLoaded1, brands2, types1, metaData1 } = useAppSelector(state => state.catalog);
  
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!productLoaded) dispatch(fetchProductsAsync());
    }, [productLoaded, dispatch])
   
    useEffect(() => {
        if (!productLoadedall) dispatch(fetchProductsAllAsync());
    }, [productLoadedall, dispatch])

    useEffect(() => {
        if (!filtersLoaded) dispatch(fetchFilters());
    }, [filtersLoaded, dispatch]);

    return {
        products,
        productsall,
        productLoaded,
        filtersLoaded,
        brands,
        types,
        metaData
    }
}