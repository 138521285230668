import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  ListSubheader,
  Typography,
  styled,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TelegramIcon from "@mui/icons-material/Telegram";

import Slider from "react-slick";
import Marquee from "react-fast-marquee";
import InfoIcon from "@mui/icons-material/Info";
import { Product } from "../../app/models/product";
// import { useAppSelector } from "../../app/store/ConfigureStore";

// import { useAppSelector } from "../../app/store/ConfigureStore";
import useProducts from "../../app/hooks/useProducts";
import { GetProductsByType } from "../../app/API/contactService";
// import Footer from "./Footer";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

interface props {
  products: Product[];
}

export default function HomePage() {
  const { productsall } = useProducts();
  const [users, setUsers] = useState<any[]>([]);
  localStorage.setItem("dataKey", JSON.stringify(productsall));

  const textFromStorage: any = JSON.parse(localStorage.getItem("dataKey"));

  useEffect(() => {
    getallContect();
  }, []);
  const getallContect = async () => {
    const response: any = await GetProductsByType();

    // console.log(response);
    setUsers(response);
  };

  const settings = {
    dots: true,

    infinite: true,
    speed: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const itemData = textFromStorage;
  // console.log(itemData);
  return (
    <>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Slider {...settings}>
              <div>
                <img
                  src="/images/mr2.png"
                  alt="GOLDEN STAR LUSTER"
                  style={{ display: "block", width: "100%", maxHeight: 400 }}
                />
              </div>

              <div>
                <img
                  src="/images/mr1.png"
                  alt="GOLDEN STAR LUSTER"
                  style={{ display: "block", width: "100%", maxHeight: 400 }}
                />
              </div>

              <div>
                <img
                  src="/images/GSL780.jpg"
                  alt="GOLDEN STAR LUSTER"
                  style={{ display: "block", width: "100%", maxHeight: 400 }}
                />
              </div>

              <div>
                <img
                  src="/images/GSL36.jpg"
                  alt="GOLDEN STAR LUSTER"
                  style={{ display: "block", width: "100%", maxHeight: 400 }}
                />
              </div>

              {/* <div>
                <img
                  src="/images/hero1.jpg"
                  alt="GOLDEN STAR LUSTER"
                  style={{ display: "block", width: "100%", maxHeight: 400 }}
                />
              </div> */}
            </Slider>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </div>

      <Box display="flex" justifyContent="center" sx={{ p: 4 }}>
        <Typography variant="h6" style={{ color: "#1976d2" }}>
          Welcome to G.S.L
           (Make people fall in love with your ideas)
           
        </Typography>
      </Box>


   
    
  

      <div
        className="container-fluid"
        style={{
          backgroundColor: "#1976d2",
          
          //   width: "1300px",
          //   marginLeft: "100px",
        }}
      >
        <Box display="flex" justifyContent="center" sx={{ p: 1 }}>
          <Typography variant="h4" style={{ color: "white" }}>
            Our Customers
          </Typography>
        </Box>

        <div
          className="container-fluid"
          style={{
            backgroundColor: "#1976d2",
            height: "400px",
            //   width: "1300px",
            //   marginLeft: "100px",
          }}
        >
          <Marquee margin-Left="250px" margin-Right="250px" direction="left">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Card sx={{ maxWidth: 345, marginTop: "50px" }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image="/images/MINISTRYOFHEALTH_LOGO.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Ministry of Health Location Dubai
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We have implemented land and decoration and attach this to the
                  site in implementing projects
                </Typography>
              </CardContent>
              {/* <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
            </CardActions> */}
            </Card>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image="/images/DAS.PNG"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  DAS Collection(Apparel) in Al Wasl, Dubai
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We have implemented land and decoration and attach this to the
                  site in implementing projects
                </Typography>
              </CardContent>
              {/* <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
            </CardActions> */}
            </Card>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image="/images/Elkhoooo.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Microtopping Alqahwa Alarabia Location Abu Dhabi
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We have implemented land and decoration and attach this to the
                  site in implementing projects
                </Typography>
              </CardContent>
            </Card>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image="/images/Salon.png"
              />
   
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Salon Man Location Silicone Dubai
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We have implemented land and decoration and attach this to the
                  site in implementing projects
                </Typography>
              </CardContent>
            </Card>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image="/images/Expo.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Expo Valley City Dubai
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We have implemented land and decoration and attach this to the
                  site in implementing projects
                </Typography>
              </CardContent>
            </Card>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Marquee>
        </div>
      </div>
  
      <div>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            {/* <h1> hello 0</h1> */}
          </Grid>
          <Grid item xs={10}>
            <div
              className="container-fluid"
              style={{
                backgroundColor: "#1976d2",
                //   marginBottom:'30px',
                //   height: "400px"
              }}
            >
              <Box display="flex" justifyContent="center" sx={{ p: 1 }}>
                <Typography variant="h4" style={{ color: "white" }}>
                  Our Vendors
                </Typography>
              </Box>
              <div
                className="container-fluid"
                style={{
                  backgroundColor: "#6b8dae",
                  marginBottom: "30px",
                  height: "400px",
                }}
              >
                <Marquee
                  margin-Left="250px"
                  margin-Right="250px"
                  direction="right"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Card sx={{ maxWidth: 345, marginTop: "50px" }}>
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="140"
                      image="/images/vendor/Mapei_logo.jpg"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Mapei
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Mapei is a leading company in the field of adhesives,
                        sealants and chemical products for building. 80 years of
                        excellence, explore the Mapei world!
                      </Typography>
                    </CardContent>
                    {/* <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
            </CardActions> */}
                  </Card>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="140"
                      image="/images/vendor/AtriaFlooring.jpg"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Atria Flooring
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Atriafloor® system's resin meets concrete and Beauty
                        explodes: presenting One True Cement™, a monolithic
                        floor full of nuances and details,.
                      </Typography>
                    </CardContent>
                    {/* <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
            </CardActions> */}
                  </Card>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Card sx={{ maxWidth: 345, marginTop: "100px" }}>
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="140"
                      image="/images/vendor/sanmarco.jpg"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        San Marco
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        San Marco is part of San Marco Group, Italy's leading
                        manufacturer and distributor of paints and varnishes
                      </Typography>
                    </CardContent>
                    {/* <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
            </CardActions> */}
                  </Card>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="140"
                      image="/images/vendor/colortek2.jpg"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Color TEK
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Colortek is Representation of wall and floor Decorative
                        Finishes, Architectural Paints, Wall Coverings,
                        Protective Coatings and Construction Chemicals.
                      </Typography>
                    </CardContent>
                    {/* <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
            </CardActions> */}
                  </Card>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Marquee>
              </div>
            </div>
          </Grid>

          <Grid item xs={1}>
            {/* <h1> hello 2</h1> */}
          </Grid>
        </Grid>
        <div
                className="container-fluid"
                style={{
                  backgroundColor: "white",
                  height: "150px",
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  
                }}
              >
              <Box display="flex" justifyContent="center" sx={{ p: 1 }}>
                <Typography variant="h4" style={{ color: "#1976d2" }}>
                 Follow Us
                </Typography>
              </Box>
      
         
      <Box display="flex" justifyContent="center" sx={{ p: 2 }}>

              <Link href="https://wa.me/+971555475259" target="_blank" style={{ color: "#00a884",  margin:"10px" }}>
                <WhatsAppIcon fontSize="large" />
              </Link>
              <Link href="https://www.facebook.com/golden.star.luster?mibextid=LQQJ4d" target="_blank" style={{ color: "#0866ff",  margin:"10px" }}>
                <FacebookIcon fontSize="large" />
              </Link>
      
              <Link href="https://www.instagram.com/golden_star_luster_microcement?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr" target="_blank" style={{ color: "#fa543c",  margin:"10px" }}>
                <InstagramIcon fontSize="large" />
              </Link>
      
              <Link href="https://t.me/GoldenStarLuster" target="_blank" style={{ color: "#2a9ed7",  margin:"10px" }}>
                <TelegramIcon fontSize="large" />
              </Link>
      
              {/* <Link href="https://youtube.com/@Goldenstarluster?si=Yx342fL3pI0V4Nh_" target="_blank" style={{ color: "#ff0000",  margin:"10px" }}>
                <YouTubeIcon fontSize="large" />
              </Link>
      
              <Link href="https://www.linkedin.com/services/page/05597932825b692a82" target="_blank" style={{ color: "#0a66c2",  margin:"10px" }}>
                <LinkedInIcon fontSize="large" />
              </Link> */}
              <Link href="https://www.tiktok.com/@golden.star.luster.93?_t=8j0hF3c08NJ&_r=1" target="_blank" style={{ color: "#0a66c2", margin:"10px" }}>
      
                <TikTokIcon />
              </Link>
      
            </Box>
      </div>

        <div
          className="container-fluid"
          style={{
            backgroundColor: "#1976d2",

            //   width: "1300px",
            //   marginLeft: "100px",
          }}
        >
          <Box display="flex" justifyContent="center" sx={{ p: 1 }}>
            <Typography variant="h4" style={{ color: "white" }}>
           Some Previous Work
            </Typography>
          </Box>
        </div>
        <ImageList>
          {users
            ? users.map((item: any) => (
                <ImageListItem key={item.id}>
                  <img
                    srcSet={`${item.pictureUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.pictureUrl}?w=248&fit=crop&auto=format`}
                    alt={item.name}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.name}
                    subtitle={item.brand}
                    actionIcon={
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        aria-label={`info about ${item.name}`}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))
            : null}
        </ImageList>
      </div>
   
      <Box
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "#1976d2",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Container maxWidth="lg">
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <Typography color="black" variant="h5" style={{ color: "white" }}>
                GOLDEN STAR LUSTER APP
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                variant="subtitle1"
                style={{ color: "white" }}
              >
                {`${new Date().getFullYear()} | Web Site | Created By | `}
                <Link
                  href="https://64b47d1c9a72cb043eefe0de--fascinating-pie-2c6e70.netlify.app/"
                  target="_blank"
                  style={{ color: "white" }}
                >
                  Kayan Soft
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* <Footer></Footer> */}
    </>
  );
}
const TikTokIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="30" width="25" 
viewBox="0 0 448 512">
  <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg>

    // <svg
    //   fill={color}
    //   xmlns="http://www.w3.org/2000/svg"
    //  viewBox="0 0 30 30"
    //   width="30px"
    //   height="30px"
    // >
    //   <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    // </svg>
  );
};
