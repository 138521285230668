import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './app/layout/styles.css'
import App from './app/layout/App';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { StoreProvider } from './app/Context/storecontext';
import { Store } from '@mui/icons-material';
// import { ConfigureStore } from './app/store/ConfigureStore';
import { Provider } from 'react-redux';
import { store } from './app/store/ConfigureStore';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
export const history = createBrowserHistory();
// store.dispatch(fatchProductsAsync()); 
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// const store = ConfigureStore();
// console.log(store.getState());
root.render(
  <StrictMode>
     <Router history={history}>
    <BrowserRouter>
 
<Provider store={store}>
  <App />
</Provider>

    </BrowserRouter>
    </Router>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
function fatchProductsAsync(): any {
  throw new Error('Function not implemented.');
}

