import {
    Button,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    LinearProgress,
    Pagination
  } from "@mui/material";
  import React, { useEffect, useState, useRef } from "react";
  import { LoadingButton } from "@mui/lab";
  import { Delete } from "@mui/icons-material";
  // import VisibilityIcon from "@mui/icons-material/Visibility";
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
  // import GetAppIcon from "@mui/icons-material/GetApp";
import usePagination from "../../app/API/usePagination";
  import jsPDF from "jspdf";
import { deleteContects, getallContects, getcontectbyId } from "../../app/API/contactService";
// import { Link,NavLink } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';
  // import { styled } from '@mui/material/styles';

  const AllContect= () => {
    const Dat1: any = sessionStorage.getItem("user");
    const DDDD: any = JSON.parse(Dat1);
    const [users, setUsers] = useState<any[]>([]);
    const data = users;
    const [loading, setLoading] = useState(false);
    const [target, setTarget] = useState(0);
    let [page2, setPage2] = useState(1);
    const PER_PAGE = 6;
    const count = Math.ceil(data.length / PER_PAGE);
    const _DATA = usePagination(data, PER_PAGE);
    
    const handleChange = (e: any, p: any) => {
      setPage2(p);
      _DATA.jump(p);
    };
    const getallContect = async () => {
      
      
      const response: any = await getallContects();
      setUsers(response);
    };

    const getallContectById = async (id:any) => {
      
      
      const response: any = await getcontectbyId(id);
      setUsers(response);
    };
    
    useEffect(() => {
      console.log("Cooker",users);
  
   getallContect();
    }, []);
  
    
    
  
  
    // const navvvv = useNavigate();
  
    const getallContectDelete = async (id:any) => {
 
        const response: any = await  deleteContects(id);
        console.log(response);
        getallContect();
    }
  
    function download() {
        let pdf = new jsPDF('p', 'pt', 'letter');
        pdf.html(document.getElementById('toPdf')!, {
            callback: function () {
                pdf.save('myDocument.pdf');
                window.open(pdf.output('bloburl')); // To debug.
            }
        });
    }
  
//    let navigate = useNavigate();
  const addevaluation = () => {
//   navvvv("/addevaluation");
  };

  
    const tableRef = useRef(null);
  
    const Print = () => {
      let printContents = document.getElementById("toPdf")!.innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    };
  
    const createPDF = async () => {
      const pdf = new jsPDF("landscape", "mm", [1280, 1024]);
      const data: any = await document.querySelector("#toPdf");
      pdf.html(data).then(() => {
        pdf.save("shipping_label.pdf");
      });
    };
    
    return (
      <>
  

    <Box display='flex' justifyContent='space-between'>
    <Button
                startIcon={<LocalPrintshopIcon />}
                onClick={() => Print()}
                sx={{ m: 2 }}
                size="large"
                variant="contained"
              >
                Print{" "}
              </Button>
                <Button onClick={() => addevaluation()} sx={{ m: 2 }}
                 startIcon={<AddCircleIcon/>}
                size='large' variant='contained' >
                  <a href="/Contact" style={{ color: 'White',textDecoration:'none' }}  >

                  Create
                  </a>
                  </Button>
            </Box>
        <div id="toPdf">
          {data ? (
            <>
              <TableContainer component={Paper}>
                <LinearProgress color="secondary" />
                <Table
                  sx={{ m: 1, ml: 1, mr: 3, width: "98%" }}
                  aria-label="simple table"
                  ref={tableRef}
                >
                  <TableHead
                    sx={{
                      m: 0,
                      boxShadow: 5,
                      border: 3,
                      borderColor: "#1976D2",
                      background: "#1976D2",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ color: "#ffffff", fontSize: 16 }}
                      >
                        ID
                      </TableCell>
                 
                      <TableCell
                        align="left"
                        sx={{ color: "#ffffff", fontSize: 16 }}
                      >
                        full Name
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#ffffff", fontSize: 16 }}
                      >
                        Phone Number
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#ffffff", fontSize: 16 }}
                      >
                        Email Address
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#ffffff", fontSize: 16 }}
                      >
                        Address
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#ffffff", fontSize: 16 }}
                      >
                        Product Name
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#ffffff", fontSize: 16 }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#ffffff", fontSize: 16 }}
                      >
                        Contact Date
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "#ffffff", fontSize: 16 }}
                      >
                        Time To Contect

                        </TableCell>

                        <TableCell
                        align="left"
                        sx={{ color: "#ffffff", fontSize: 16 }}
                      >
                      </TableCell>
                   
                    </TableRow>
                  </TableHead>
  
                  <TableBody>
                    {_DATA.currentData().map((x: any,index:any) => (
                      
  
                      <TableRow
                        key={x.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {x.id}
                        </TableCell>
                 
                        <TableCell align="center">
                          {x.fullName}
                        </TableCell>
                        
                        <TableCell align="left">
                          {x.phone}
                        </TableCell>

                        <TableCell align="left">
                          {x.email}
                        </TableCell>
                      
                        <TableCell align="left">
                          {x.address}
                        </TableCell>

                        <TableCell align="left">
                          {x.productName}
                        </TableCell>
               
                        <TableCell align="left">
                          {x.desc}
                        </TableCell>
                        <TableCell align="left">
                          {x.conDate.length <= 10
                            ? x.conDate
                            : x.conDate.substr(0, 10) + "..."}
                        </TableCell>
                        <TableCell align="left">
                          {x.timeToContect}
                        </TableCell>
                        <TableCell align="right">

{/* <LoadingButton
  startIcon={<Edit />}
  component={Link}
  to={`/EditComplainsForm/${x.id}`}
></LoadingButton> */}

<LoadingButton
  loading={loading && target === x.id}
  startIcon={<Delete />}
  color="error"
  onClick={() => getallContectDelete(x.id)}
/>
</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>{" "}
            </>
          ) : (
            <span>No results</span>
          )}
  
          <Box
            display="flex"
            sx={{ m: 1, ml: 3, mr: 3, width: "98%" }}
            justifyContent="space-between"
          >
            {/* <Pagination color="secondary" count={users.length} onChange={(e, value) => setPageApi(value)} /> */}
            <Pagination
              count={count}
              size="large"
              color="secondary"
              page={page2}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </Box>
        </div>
      </>
    );
  };
  
  export default AllContect;
  