import * as yup from 'yup';

export const validationSchema = [
    yup.object({
        fullName: yup.string().required('Full name is required'),
        address1: yup.string().required('Addres line 1 is required'),
        address2: yup.string().required('Addres line 2 is required'),
        city: yup.string().required('city  is required'),
        state: yup.string().required('state is required'),
        zip: yup.string().required('zip is required'),
        country: yup.string().required('country is required'),
    }),
    yup.object(),
    yup.object({
        nameOnCard: yup.string().required('nameOnCard is required')
    })
] 