import { TableContainer, Paper, Table, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import React from 'react'
// import { useStoreContext } from "../../app/Context/storecontext";
import { useAppSelector } from "../../app/store/ConfigureStore";
import { currencyFormat } from "../../app/util/util";

interface Props {
    subtotal?: number;
}

export default function BasketSummary({subtotal}: Props) {
    const {basket} = useAppSelector(state => state.basket);
    if (basket.items){
    if (subtotal === undefined)
       subtotal = basket.items.reduce((sum, item) => sum + (item.quantity * item.price), 0) ;
    const deliveryFee = subtotal > 10000 ? 0 : 500;

    return (
        <>
            <TableContainer component={Paper} variant={'outlined'}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>Subtotal</TableCell>
                            <TableCell align="right">{currencyFormat(subtotal)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>Delivery fee*</TableCell>
                            <TableCell align="right">{currencyFormat(deliveryFee)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>Total</TableCell>
                            <TableCell align="right">{currencyFormat(subtotal + deliveryFee)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <span style={{fontStyle: 'italic'}}>*Orders over $100 qualify for free delivery</span>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            
        </>
    )}
}

// function useAppSelector(arg0: (state: any) => any): { basket: any; } {
//     throw new Error("Function not implemented.");
// }
// function currencyFormat(subtotal: number | undefined): import("react").ReactNode {
//     throw new Error("Function not implemented.");
// }

