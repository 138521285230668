import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Dialog, ListItemButton, Stack } from '@mui/material';
// import CartIcon from './CartIcon';
// import SearchForm from './SearchForm';
import { AccountCircle, Search } from '@mui/icons-material';
// import { mainListItems } from './listItems';
// import { useState } from 'react';
import AccountIcon from './AccountIcon';
import ListItems from './listItems';
// import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link } from "react-router-dom";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import InfoIcon from '@mui/icons-material/Info';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
// import * as React from 'react';
// import { Box } from '@mui/material';
import { useState } from 'react';

const drawerWidth = 220;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function MainNav() {
  const [searchOpen, setSearchOpen] = useState(false)
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);

  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar position="sticky" open={open} >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          {/*Search Functionality start */}
          <Box sx={{display:{xs:'none', sm:'block'}}}>
            {/* <SearchForm open={searchOpen} setOpen={setSearchOpen}/> */}
          </Box>
          <Box sx={{flexGrow:1}}/>
          <Stack gap={1} direction="row" alignContent='center' >
            <IconButton sx={{ display: { xs: 'inherit', sm: 'none' }, color: 'white' }}
            onClick={() => setSearchOpen(true)}>
              <Search />
            </IconButton>
            {/*Cart  */}
            {/* <CartIcon /> */}
            {/*Account  */}
            <AccountIcon/>
            {/* <AccountCircle/> */}
          </Stack>
        </Toolbar>
      </AppBar>
       {/*Search Functionality SmallScreen */}
    <Dialog
      open={searchOpen}
      onClose={() => setSearchOpen(false)}
      maxWidth="xs"
      PaperProps={{
        sx:{
          position: 'fixed',
          top:10
        }
      }}
      sx={{display:{xs:'block', sm: 'none'}}}>

    {/* <SearchForm open={searchOpen} setOpen={setSearchOpen}/> */}
    </Dialog>
      <Drawer
        sx={{
          width: drawerWidth ,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: '#1976d2'
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} style={{color: 'white'}}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List component="nav" >
        <Box style={{color: 'white'}}>
  <ListItemButton component={Link} to="/" onClick={handleDrawerClose}>
      <ListItemIcon >
        <HomeIcon style={{color: 'white'}}  />
      </ListItemIcon>
      <ListItemText primary="Home" onClick={handleDrawerClose} />
    </ListItemButton>
    <ListItemButton component={Link} to="/Catalog" onClick={handleDrawerClose}>
      <ListItemIcon >
        <DashboardIcon style={{color: 'white'}} />
      </ListItemIcon>
      <ListItemText primary="Dashboard" onClick={handleDrawerClose}/>
    </ListItemButton>
    <ListItemButton component={Link} to="/inventory" onClick={handleDrawerClose}>
      <ListItemIcon >
        <InventoryIcon style={{color: 'white'}}/>
      </ListItemIcon>
      <ListItemText primary="Inventory" onClick={handleDrawerClose} />
    </ListItemButton>
    
    <ListItemButton component={Link} to="/catalog" onClick={handleDrawerClose}>
      <ListItemIcon>
        <ProductionQuantityLimitsIcon style={{color: 'white'}}/>
      </ListItemIcon >

      <ListItemText primary="Product" onClick={handleDrawerClose} />
    </ListItemButton>

    <ListItemButton component={Link} to="/allContect" onClick={handleDrawerClose}>
      <ListItemIcon>
        <ProductionQuantityLimitsIcon style={{color: 'white'}}/>
      </ListItemIcon >
      
      <ListItemText primary="Requsts" onClick={handleDrawerClose} />
    </ListItemButton>

    <ListItemButton component={Link} to="/About" onClick={handleDrawerClose} >
      <ListItemIcon>
        <InfoIcon style={{color: 'white'}}/>
      </ListItemIcon>
      <ListItemText primary="About" onClick={handleDrawerClose}/>
    </ListItemButton>
    <ListItemButton  component={Link} to="/Contact" onClick={handleDrawerClose}>
      <ListItemIcon>
        <ConnectWithoutContactIcon style={{color: 'white'}}/>
      </ListItemIcon>
      <ListItemText primary="Contact" onClick={handleDrawerClose} />
    </ListItemButton>
  </Box>
          </List>
        <Divider />
      </Drawer>
    </Box>
  );
}